







































































































































































































import { Component, Prop } from "vue-property-decorator";
import { BaseForm } from "./BaseForm";

@Component({
  components: {},
})
export default class RegisterForm extends BaseForm {
  public labels: Record<string, string> = {
    firstName: "First Name",
    surName: "Surname",
    mobileNo: "Mobile No",
    dob: "Date of Birth",
    gender: "Gender",
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm Password",
  };

  @Prop({ required: true })
  public signinUrl!: string;

  public model: Record<string, any> = {
    firstName: null,
    surName: null,
    mobileNo: null,
    dob: null,
    gender: null,
    email: null,
    password: null,
    confirmPassword: null,
  };

  public async send() {
    this.$emit("done", {});
  }
}
